.flex {
  display: flex;
}

.flexStart {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.flexColumn {
  display: flex;
  flex-direction: column;
}
.flexEnd {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.flexCenter {
  display: flex;
  justify-content: center;
}

.flexWrap {
  flex-wrap: wrap;
}

.alignCenter {
  display: flex;
  align-items: center;
}

.width10 {
  width: 10%;
}

.width20 {
  width: 20%;
}

.width25 {
  width: 25%;
}

.width30 {
  width: 30% !important;
}

.width33 {
  width: 33% !important;
}

.width40 {
  width: 40% !important;
}

.width45 {
  width: 45% !important;
}

.width50 {
  width: 50% !important;
}

.width60 {
  width: 60%;
}

.width70 {
  width: 70%;
}

.width75 {
  width: 75%;
}

.width80 {
  width: 80% !important;
}

.width90 {
  width: 90%;
}
.width100 {
  width: 100% !important;
}

.right10 {
  margin-right: 10px !important;
}

.right20 {
  margin-right: 15px !important;
}

.left10 {
  margin-left: 19px;
}

.left20 {
  margin-left: 19px;
}

.top5 {
  margin-top: 5px !important;
}
.top10 {
  margin-top: 10px !important;
}
.top15 {
  margin-top: 15px !important;
}

.top20 {
  margin-top: 20px !important;
}

.moduleList {
  overflow-y: auto;
  max-height: 400px;
  height: 400px;
  position: relative;
}

.moduleItem {
  margin-bottom: 2px !important;
}

// LABEL
.labelPermisison {
  margin-top: 5px !important;
  color: rgba(0,0,0,.85);
}