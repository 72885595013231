.button-text{
    color: #1890ff;
    cursor: pointer;
    &:active{
        color: #096dd9;
    }

    &:hover{
        color: #40a9ff;
    }
}