/**
 * FLEX GRID
 */

 .flex-container {
  width: 100%;
  margin: 0 auto;
}

.flex-basis {
  flex-basis: 0;
}

.basis-auto {
  flex-basis: auto;
}

.flex-main {
  flex: 1 100%; // All items to be 100% width, via flex-basis
}

.flex-row,
.flex-col,
.wrapper,
.wrapper-flip {
  display: flex;
  position: relative;
}

// WRAP
.wrapper {
  flex-flow: row wrap;
}

.wrapper-flip {
  flex-flow: row wrap-reverse;
}

.wrapper-col {
  flex-flow: column wrap;
}
// ======= END =======

// DIRECTION
.flex-row {
  flex-flow: row nowrap;
}

.flex-col {
  flex-flow: column nowrap;
}

.flip-row {
  flex-flow: row-reverse nowrap;
}

.flip-col {
  flex-flow: column-reverse nowrap
}
// ======= END =======

// JUSTIFY CONTENT
.justify-start {
  justify-content: flex-start;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-evenly {
  justify-content: space-evenly;
}
// ======= END =======

// ALIGN ITEMS
.items-start {
  align-items: flex-start;
}

.items-center {
  align-items: center;
}

.items-end {
  align-items: flex-end;
}

.items-baseline {
  align-items: baseline;
}

.items-stretch {
  align-items: stretch;
}
// ======= END =======

// ALIGN CONTENTS
.content-start {
  align-content: flex-start;
}
.content-center {
  align-content: center;
}
.content-end {
  align-content: flex-end;
}
.content-stretch {
  align-content: stretch;
}
.content-between {
  align-content: space-between;
}
.content-around {
  align-content: space-around;
}
// ======= END =======

// ALIGN SELF
.self-start {
  align-self: flex-start;
}

.self-center {
  align-self: center;
}

.self-end {
  align-self: flex-end;
}
// ======= END =======

// ENLARGE
.grow-1 {
  flex: 1 1 auto;
}

.grow-1-0 {
  flex: 1 0 auto;
}

.grow-1-half {
  flex-grow: 1.5;
}

.grow-2 {
  flex-grow: 2;
}

.grow-2-half {
  flex-grow: 2.5;
}

.grow-3 {
  flex-grow: 3;
}

.grow-3-half {
  flex-grow: 3.5;
}

@media (max-width: 768px) {
  .wrapper:not(.wrapper-skip-min) {
    flex-flow: column wrap;
  }

  .flex-col-xs {
    flex-flow: column wrap;
  }

  .flex-basis-auto {
    flex-basis: auto;
  }

  .items-center-xs {
    align-items: center;
  }

  .items-start-xs {
    align-items: start;
  }

  .col-rev-xs {
    flex-direction: column-reverse !important;
  }
}
