$bg-color: #ffffff !default;
.sidebar{
    .sideToggle{
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        z-index: 1;
        pointer-events: none;
    }

    .toggle-icon{
        padding: 5px;
        color: #1890ff;
        pointer-events: all;
        cursor: pointer;
    }
}

.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  margin: 0;
}

