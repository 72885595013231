.editable-cell {
    position: relative;
}

.editable-cell-value-wrap {
    padding: 5px 12px;
    cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    padding: 4px 11px;
}

[data-theme="dark"] .editable-row:hover .editable-cell-value-wrap {
    border: 1px solid #434343;
}

// OTHER TABLE STYLES
.table-row-default {
    background-color: rgba(250, 250, 250, 1);
}

.table-row-warning {
    background-color: rgba(255, 0, 0, 0.2);
}

.table-row-new {
    background-color: rgba(240, 255, 0, 0.5);
}

.table-row-moved {
    background-color: rgba(255, 0, 0, 0.2);
}

.table-row-closed {
    background-color: rgba(17, 255, 0, 0.2);
}

.ant-table-tbody > tr .ant-table-wrapper:only-child .ant-table {
    margin: -8px -8px -8px 40px !important;
}

.tbl-not-expandable {
    .ant-table-row-expand-icon {
        opacity: 0.2;
        pointer-events: none;
    }
}