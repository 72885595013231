// TEXT TRANSFORM
.transform-capitalize {
  text-transform: capitalize;
}

.transform-uppercase {
  text-transform: uppercase;
}

.transform-lowercase {
  text-transform: lowercase;
}

// COLUMN
.desc-max-width {
  max-width: 300px;
}

// ANTD INPUT SEARCH
.ant-input-search-icon:before {
  position: absolute !important;
  top: 0 !important;
  bottom: 0 !important;
  display: block !important;
  border-left: 1px solid #d9d9d9 !important;
  -webkit-transition: all .3s !important;
  transition: all .3s !important;
  content: "" !important;

  -webkit-transform: translateX(-12px) !important;
  transform: translateX(-12px) !important;
}

.ant-input-search-icon {
  margin-left: 1.3em !important;
}

.ant-input-clear-icon {
  font-size: 15px !important;
  vertical-align: -1.5px !important;
}

.ant-table-cell-override {
  padding: 0 3px !important;
}

.custom-label-wrap > .ant-form-item-label {
  white-space: pre-wrap !important;
}

.custom-label-wrap > .ant-form-item-label > label {
  display: flex;
  align-items: flex-start;
  padding-top: 1.5px;
}

.color-red {
  color: #FF0000;
}

.ant-menu-item {
  margin: 0 !important;
}

.center-item-margin {
  margin: 0 auto;
  text-align: center;
}

// TEXT ALIGN
.text-align-left {
  text-align: left;
}