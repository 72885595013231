.daily-sales-report-container {
  padding: 20px 0;
  width: calc(100% / 4 - 8px);
  background-color: #e6f7ff;
  border-bottom: 3px solid #e6f7ff;
}

.daily-collections-report-container {
  padding: 20px 0;
  width: calc(100% / 4 - 8px);
  background-color: #e6f7ff;
  border-bottom: 3px solid #e6f7ff;
}

.daily-sales-report-container.active, .daily-collections-report-container.active {
  border-bottom: 3px solid #40a9ff;
}

.daily-sales-report-container:hover, .daily-collections-report-container:hover {
  border-bottom: 3px solid #40a9ff;
}

//ANTD TAB
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
  background: #fafafa !important;
}

#tab-tab-1, #tab-tab-2, #tab-tab-3 {
  padding: 10px !important;
  margin-right: 1px !important;
  width: 160px;
  font-size: 14px;
  line-height: normal;
  white-space: pre-wrap;
  background: #fff;
  text-align: center;
  border-right: 1px solid #f0f0f0;
}

.daily-sales-report-tab, .daily-collections-report-tab {
  width: calc(100% - 160px);
}

.daily-sales-report-content .ant-tabs .ant-tabs-left-bar, .daily-collections-report-content .ant-tabs .ant-tabs-left-bar {
  border-right: 1px solid transparent !important;
}

.daily-sales-report-content .ant-tabs .ant-tabs-left-content, .daily-collections-report-content .ant-tabs .ant-tabs-left-content {
  border-left: 1px solid transparent !important;
}

.aging-report-tbl > td {
  padding: 0 !important;
}